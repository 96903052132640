* {
  box-sizing: border-box;
  line-height: 1.618;
  transition: all 0.3s linear;
}
body {
  margin: 0;
  font-family: "Fira Code", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fcfcfc;
  background: #111;
}
code {
  font-family: "Fira Code", monospace;
}
.f2 {
  font-family: 'Gamja Flower', monospace;
}
.date-str {
  color: #aabb88;
}
a {
  text-decoration: none;
  color: #aff;
}
a:hover,
a:focus,
a:active {
  color: #0ff;
  text-shadow: 0 0 5px #0ef;
}
#root {
  min-height: 100vh;
  padding-bottom: 300px;
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: 100%;
}
.container {
  max-width: 1360px;
  margin: 0 auto;
}
.padded {
  padding: 20px 40px;
}
.padded-sm {
  padding: 20px;
}
.spaced {
  margin-top: 40px;
}
.left-col {
  padding-right: 20px;
}
.right-col {
  padding-left: 20px;
}
.box {
  background: #00212fb0;
  border-radius: 8px;
  border: 1px #79d solid;
  box-shadow: 0 0 5px #5af, inset 0 0 5px #5af, 0 0 50px #000;
  overflow: hidden;
}
.loading-container {
  width: 100%;
  height: 400px;
  min-height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.loading-container h3 {
  z-index: 1;
}
.header {
  height: 64px;
  display: flex;
  align-items: center;
  line-height: 64px;
}
.header .header-left {
  text-align: left;
}
.header .header-left h3 {
  margin: 0;
  font-size: 20px;
}
.header .header-right {
  text-align: right;
}
.header .header-right ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  list-style-type: none;
  font-size: 16px;
}
.header .header-right ul li {
  display: inline-block;
  padding-left: 40px;
}
i + span,
span + i {
  display: inline-block;
  height: 1.5em;
  line-height: 1.5em;
  vertical-align: middle;
}
.em.lg,
.em.lg + span {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
}
.hero .hero-image {
  height: 200px;
  width: auto;
}
.hero .image-container {
  width: 150px;
  min-width: 150px;
  height: 200px;
  margin-right: 40px;
  border-radius: 8px;
  border: 1px #79d solid;
  overflow: hidden;
  text-align: center;
  background: #f0ff8c;
}
.hero .monologue {
  width: 100%;
}
.hero .monologue h1,
.hero .monologue h2 {
  margin: 0;
  margin-bottom: 20px;
}
.hero .monologue h1 span,
.hero .monologue h2 span {
  vertical-align: middle;
}
.hero .monologue h1 {
  font-size: 32px;
}
.hero .monologue h2 {
  font-size: 20px;
}
.hero .monologue .icons {
  margin-top: 50px;
  font-size: 32px;
}
.hero .monologue .icons span:not(:last-child) {
  margin-right: 20px;
}
.t1,
.t2 {
  margin: 0;
  font-size: 32px;
}
.t1 i + span,
.t2 i + span,
.t1 span + i,
.t2 span + i {
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
}
.t2 {
  font-size: 20px;
}
.centered {
  display: flex;
  align-items: center;
}
.tac {
  text-align: center;
}
.main {
  padding: 40px;
}
.bio {
  font-size: 16px;
  margin-top: 20px;
}
.snippet-list {
  margin-top: 20px;
}
.org-snippet {
  border-radius: 8px;
  border: 1px #79d solid;
  margin-right: 10px;
  margin-bottom: 10px;
}
.org-snippet header.with-border-bottom {
  border-bottom: 1px #79d solid;
}
.org-snippet header {
  display: flex;
  padding: 10px;
  align-items: center;
  background: #0004;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.org-snippet header:hover {
  background: #00a8;
  cursor: pointer;
}
.org-snippet header .org-header-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.org-snippet header .org-header-btn .em {
  transition: transform 0.2s linear;
}
.org-snippet header .org-header-btn .rotate180 {
  transform: rotate(180deg);
}
.org-snippet header .org-header-btn span {
  margin-right: 10px;
}
.org-snippet header .org-header-btn:hover span {
  font-weight: bold;
}
.org-snippet img {
  width: 64px;
  height: 64px;
  margin-right: 20px;
}
.org-snippet h3,
.org-snippet h4 {
  margin: 0;
}
.org-snippet .org-content-wrapper {
  width: 100%;
}
.org-snippet .org-content-wrapper ul.work-list {
  padding: 0;
  margin: 0;
  margin-left: 32px;
}
@media screen and (min-width: 640px) {
  .org-snippet .org-content-wrapper ul.work-list {
    margin-left: 64px;
  }
}
.org-snippet .org-content-wrapper ul.work-list > li {
  padding-right: 20px;
  border-bottom: 1px #79d dashed;
}
.org-snippet .org-content-wrapper ul.work-list > li:last-child {
  border-bottom: none;
}
.org-snippet .org-content-wrapper ul.work-list > li p {
  margin: 10px 0;
  font-weight: 700;
}
.lbl {
  display: inline-block;
  padding: 0 10px;
  background: #fcfcfc;
  color: black;
  border: 2px black dotted;
}
.sidebar {
  padding-left: 40px;
}
ul.skills-list {
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
}
ul.skills-list li {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 1in;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #5af;
  color: black;
  font-weight: bold;
}
ul.skills-list li a {
  color: black;
}
ul.skills-list.sm {
  margin-top: 10px;
}
ul.skills-list.sm li {
  font-size: 14px;
}
ul.skills-list.outlined li {
  background: transparent;
  border: 1px #79d solid;
  color: #79d;
}
ul.hacks-list {
  list-style-type: none;
  padding: 0;
}
ul.hacks-list li {
  display: block;
  padding: 20px 0;
}
ul.hacks-list li:not(:last-child) {
  border-bottom: 1px #79d solid;
}
ul.hacks-list li p {
  font-size: 14px;
  margin: 0;
}
ul.hacks-list li h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
ul.hacks-list li a {
  color: #d84;
}
ul.hacks-list li a:hover {
  color: yellow;
  text-shadow: 0 0 5px red;
}
ul.hacks-list li a.link-btn {
  color: #3a3a3a;
}
ul.hacks-list li a.link-btn:hover {
  color: black;
  text-shadow: none;
}
.mb {
  margin-bottom: 20px;
}
.link-btn,
.action-btn {
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
  background: #0ef;
  box-shadow: 0 0 20px #3a3a3a;
  color: #3a3a3a;
}
.link-btn:hover,
.action-btn:hover {
  color: black;
  background: #69f;
  text-shadow: none;
}
.action-btn {
  width: fit-content;
  margin-right: 20px;
  font-weight: bold;
  font-size: 20px;
}
.action-btn * {
  vertical-align: middle;
}
.tac .action-btn {
  margin-right: 0;
}
.spread {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dot.green {
  background: #0ef;
  box-shadow: 0 0 10px #79d;
}
.divider-text {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 20px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
}
.divider-text:before {
  content: ' ';
}
.nm {
  margin: 0 0 0 0;
}
.blt {
  border-left: 1px #79d solid;
}
.bb {
  border-bottom: 1px #79d solid;
}
.blogpost-snippet:not(:last-child) {
  border-bottom: 1px #79d dotted;
}
.blogpost-snippet:not(:last-child) p {
  margin-bottom: 40px;
}
.blogpost-snippet h3,
.blogpost-snippet h4,
.blogpost-snippet ul {
  margin: 0.31em 0;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-pages-box h2 {
  margin: 0;
}
.view-pages-box a {
  font-size: 20px;
  font-weight: bold;
  background: #0ef;
  color: #3a3a3a;
}
.view-pages-box a:hover {
  background: #69f;
  color: black;
}
.projects-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.projects-hero img {
  height: 300px;
  filter: drop-shadow(0 0 100px #0ff) drop-shadow(0 0 100px #0ef);
}
.projects-hero h1 {
  font-size: 48px;
  color: #0ef;
  margin: 0;
}
.projects-hero h1 * {
  vertical-align: middle;
  line-height: 48px;
}
.projects-hero h1 span {
  height: 48px;
  line-height: 48px;
}
.project-box h3,
.project-box h4 {
  margin: 0.31em 0;
}
.project-box h3 {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-box h3 a {
  color: #aabb88;
}
.project-box h3 a:hover,
.project-box h3 a:focus,
.project-box h3 a:active {
  color: yellow;
}
.project-box h4 {
  color: #0ef;
  font-weight: bold;
}
.gallery-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery-small img {
  width: 256px;
  height: 256px;
  margin: 20px;
  background: black;
  border: 1px #79d solid;
}
.clickable {
  cursor: pointer;
  z-index: 2;
  filter: sepia(0.4);
}
.clickable:hover {
  transform: scale(1.1);
  box-shadow: 0 0 50px #0005;
  filter: none;
}
.app-wrapper.bg {
  opacity: 0.8;
  filter: blur(5px);
}
.image-wrapper {
  position: relative;
}
.image-wrapper .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.image-wrapper .image-overlay.show {
  display: block;
  background: #0008;
}
.image-wrapper .image-overlay.show:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  border: 10px #0ef solid;
  border-top: 10px #0f02 solid;
  box-shadow: 0 0 50px #0f05, inset 0 0 50px #0f05;
  animation: 1s spin linear infinite;
}
.image-wrapper img.hide-o {
  opacity: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overlay {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #0008;
  display: none;
  align-items: center;
  justify-content: center;
}
.overlay.active {
  display: flex;
}
.overlay .modal {
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  width: 75vh;
}
.overlay .modal .modal-image-wrapper {
  position: relative;
}
.overlay .modal .modal-image-wrapper img {
  width: 75vh;
  height: 75vh;
}
.overlay .modal .modal-image-wrapper .next-button,
.overlay .modal .modal-image-wrapper .prev-button,
.overlay .modal .modal-image-wrapper .close-button {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: none;
  box-shadow: 0 0 20px #0005;
  color: black;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear;
  top: 35vh;
}
.overlay .modal .modal-image-wrapper .prev-button {
  left: 20px;
}
.overlay .modal .modal-image-wrapper .next-button {
  right: 20px;
}
.overlay .modal .modal-image-wrapper .close-button {
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}
.overlay .modal .modal-image-wrapper:hover .next-button,
.overlay .modal .modal-image-wrapper:hover .prev-button,
.overlay .modal .modal-image-wrapper:hover .close-button {
  opacity: 1;
}
ul.tags-list-sidebar {
  padding: 0;
  list-style-type: none;
}
ul.tags-list-sidebar li {
  padding: 0.31em;
  margin: 0.31em 0;
  font-size: 20px;
  font-weight: bold;
}
ul.tags-list-sidebar li:hover {
  background: #0008;
}
.post-cover {
  width: 100%;
  height: auto;
  max-height: 500px;
}
.md-content {
  color: #fcfcfc;
}
.md-content p {
  line-height: 1.618;
  font-size: 18px;
}
.md-content img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.md-content strong {
  font-weight: bold;
}
.md-content pre {
  background: #0008;
  border-radius: 8px;
  padding: 20px;
  overflow-x: auto;
}
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.game-container .game-btn {
  padding: 10px 20px;
  margin-top: 20px;
  border: 1px #0ef solid;
  box-shadow: 0 0 15px #0ef;
}
.game-container .game-btn:hover {
  box-shadow: 0 0 5px #0ef;
}
.game-container .hlt {
  background: #0fa3;
}
.game-container .board {
  position: relative;
}
.game-container .board .dim {
  filter: grayscale(0.5) blur(5px);
}
.game-container .board .board-row {
  margin: 0;
  display: flex;
}
.game-container .board .board-row:last-child .game-cell {
  border-bottom: none;
}
.game-container .board .board-row .game-cell {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  font-size: 48px;
  line-height: 48px;
  border: 2px #0ef solid;
  border-top: none;
  border-left: none;
}
.game-container .board .board-row .game-cell:last-child {
  border-right: none;
}
.game-container .board .board-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}
.game-container .board .board-overlay.active {
  display: flex;
}
.game-container .board .board-overlay .choose-sign {
  display: flex;
  margin: 20px;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border: 1px #0ef solid;
  box-shadow: 0 0 25px #0ef;
  font-size: 48px;
}
.game-container .board .board-overlay .choose-sign:hover {
  background: #0008;
  box-shadow: 0 0 10px #0ef;
}
.game-container .game-clickable {
  cursor: pointer;
}
.pulsar {
  animation: pulse-shadow 1s ease-in-out infinite;
  border: 1px #0ff solid;
}
@keyframes pulse-shadow {
  0%,
  100% {
    box-shadow: 0 0 10px #aff;
  }
  50% {
    box-shadow: 0 0 20px #aff;
  }
}
@media only screen and (max-width: 1080px) {
  .no-mob {
    display: none;
  }
  .spaced {
    margin-top: 20px;
  }
  .left-col,
  .right-col {
    padding: 0 0 0 0;
  }
  .container {
    padding: 0 5px;
  }
  .padded {
    padding: 10px 10px;
  }
  .mob-center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .blt {
    border-left: none;
    border-top: 1px #79d solid;
  }
  .header .header-right ul li {
    padding-left: 20px;
  }
  .hero .image-container {
    margin-right: 0;
    height: 200px;
  }
  .hero .monologue {
    margin-top: 20px;
  }
  .hero .monologue h1 {
    font-size: 24px;
  }
  .hero .monologue h2 {
    font-size: 20px;
  }
  .hero .monologue .icons {
    font-size: 24px;
    margin-top: 20px;
  }
  .t1 {
    font-size: 24px;
  }
  .t2 {
    font-size: 20px;
  }
  .main {
    padding-top: 40px;
  }
  .sidebar {
    padding-left: 0;
  }
  .view-pages-box a > span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .projects-hero h1 {
    font-size: 32px;
  }
  .overlay .modal .modal-image-wrapper img {
    width: 100%;
    height: auto;
  }
  .overlay .modal .modal-image-wrapper .close-button {
    opacity: 0.5;
  }
  .md-content p {
    font-size: 14px;
  }
  .post-cover {
    max-height: 300px;
  }
}
