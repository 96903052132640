.cell,
.cell-des-d-1,
.cell-des-n-1,
.cell-des-o-1,
.cell-des-d-2,
.cell-des-n-2,
.cell-des-o-2,
.cell-des-d-3,
.cell-des-n-3,
.cell-des-o-3,
.cell-des-d-4,
.cell-des-n-4,
.cell-des-o-4,
.cell-des-d-5,
.cell-des-n-5,
.cell-des-o-5,
.cell-des-d-6,
.cell-des-n-6,
.cell-des-o-6,
.cell-des-d-7,
.cell-des-n-7,
.cell-des-o-7,
.cell-des-d-8,
.cell-des-n-8,
.cell-des-o-8,
.cell-des-d-9,
.cell-des-n-9,
.cell-des-o-9,
.cell-des-d-10,
.cell-des-n-10,
.cell-des-o-10,
.cell-des-d-11,
.cell-des-n-11,
.cell-des-o-11,
.cell-des-d-12,
.cell-des-n-12,
.cell-des-o-12,
.cell-des-d-13,
.cell-des-n-13,
.cell-des-o-13,
.cell-des-d-14,
.cell-des-n-14,
.cell-des-o-14,
.cell-des-d-15,
.cell-des-n-15,
.cell-des-o-15,
.cell-des-d-16,
.cell-des-n-16,
.cell-des-o-16 {
  position: relative;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
}
.cell > :not(.cell) {
  width: 100%;
}
.cell-w-narrow {
  width: fit-content;
}
.cell-w-grow {
  flex-grow: 1;
}
.cell-des-d-1 > .cell-des-n-1,
.cell-des-d-2 > .cell-des-n-2,
.cell-des-d-3 > .cell-des-n-3,
.cell-des-d-4 > .cell-des-n-4,
.cell-des-d-5 > .cell-des-n-5,
.cell-des-d-6 > .cell-des-n-6,
.cell-des-d-7 > .cell-des-n-7,
.cell-des-d-8 > .cell-des-n-8,
.cell-des-d-9 > .cell-des-n-9,
.cell-des-d-10 > .cell-des-n-10,
.cell-des-d-11 > .cell-des-n-11,
.cell-des-d-12 > .cell-des-n-12,
.cell-des-d-13 > .cell-des-n-13,
.cell-des-d-14 > .cell-des-n-14,
.cell-des-d-15 > .cell-des-n-15,
.cell-des-d-16 > .cell-des-n-16 {
  width: 100%;
}
.cell-des-d-1 > .cell-des-o-1,
.cell-des-d-2 > .cell-des-o-2,
.cell-des-d-3 > .cell-des-o-3,
.cell-des-d-4 > .cell-des-o-4,
.cell-des-d-5 > .cell-des-o-5,
.cell-des-d-6 > .cell-des-o-6,
.cell-des-d-7 > .cell-des-o-7,
.cell-des-d-8 > .cell-des-o-8,
.cell-des-d-9 > .cell-des-o-9,
.cell-des-d-10 > .cell-des-o-10,
.cell-des-d-11 > .cell-des-o-11,
.cell-des-d-12 > .cell-des-o-12,
.cell-des-d-13 > .cell-des-o-13,
.cell-des-d-14 > .cell-des-o-14,
.cell-des-d-15 > .cell-des-o-15,
.cell-des-d-16 > .cell-des-o-16 {
  margin-left: 100%;
}
.cell-des-d-2 > .cell-des-n-1,
.cell-des-d-4 > .cell-des-n-2,
.cell-des-d-6 > .cell-des-n-3,
.cell-des-d-8 > .cell-des-n-4,
.cell-des-d-10 > .cell-des-n-5,
.cell-des-d-12 > .cell-des-n-6,
.cell-des-d-14 > .cell-des-n-7,
.cell-des-d-16 > .cell-des-n-8 {
  width: 50%;
}
.cell-des-d-2 > .cell-des-o-1,
.cell-des-d-4 > .cell-des-o-2,
.cell-des-d-6 > .cell-des-o-3,
.cell-des-d-8 > .cell-des-o-4,
.cell-des-d-10 > .cell-des-o-5,
.cell-des-d-12 > .cell-des-o-6,
.cell-des-d-14 > .cell-des-o-7,
.cell-des-d-16 > .cell-des-o-8 {
  margin-left: 50%;
}
.cell-des-d-3 > .cell-des-n-1,
.cell-des-d-6 > .cell-des-n-2,
.cell-des-d-9 > .cell-des-n-3,
.cell-des-d-12 > .cell-des-n-4,
.cell-des-d-15 > .cell-des-n-5 {
  width: 33.33333333%;
}
.cell-des-d-3 > .cell-des-o-1,
.cell-des-d-6 > .cell-des-o-2,
.cell-des-d-9 > .cell-des-o-3,
.cell-des-d-12 > .cell-des-o-4,
.cell-des-d-15 > .cell-des-o-5 {
  margin-left: 33.33333333%;
}
.cell-des-d-3 > .cell-des-n-2,
.cell-des-d-6 > .cell-des-n-4,
.cell-des-d-9 > .cell-des-n-6,
.cell-des-d-12 > .cell-des-n-8,
.cell-des-d-15 > .cell-des-n-10 {
  width: 66.66666667%;
}
.cell-des-d-3 > .cell-des-o-2,
.cell-des-d-6 > .cell-des-o-4,
.cell-des-d-9 > .cell-des-o-6,
.cell-des-d-12 > .cell-des-o-8,
.cell-des-d-15 > .cell-des-o-10 {
  margin-left: 66.66666667%;
}
.cell-des-d-4 > .cell-des-n-1,
.cell-des-d-8 > .cell-des-n-2,
.cell-des-d-12 > .cell-des-n-3,
.cell-des-d-16 > .cell-des-n-4 {
  width: 25%;
}
.cell-des-d-4 > .cell-des-o-1,
.cell-des-d-8 > .cell-des-o-2,
.cell-des-d-12 > .cell-des-o-3,
.cell-des-d-16 > .cell-des-o-4 {
  margin-left: 25%;
}
.cell-des-d-4 > .cell-des-n-3,
.cell-des-d-8 > .cell-des-n-6,
.cell-des-d-12 > .cell-des-n-9,
.cell-des-d-16 > .cell-des-n-12 {
  width: 75%;
}
.cell-des-d-4 > .cell-des-o-3,
.cell-des-d-8 > .cell-des-o-6,
.cell-des-d-12 > .cell-des-o-9,
.cell-des-d-16 > .cell-des-o-12 {
  margin-left: 75%;
}
.cell-des-d-5 > .cell-des-n-1,
.cell-des-d-10 > .cell-des-n-2,
.cell-des-d-15 > .cell-des-n-3 {
  width: 20%;
}
.cell-des-d-5 > .cell-des-o-1,
.cell-des-d-10 > .cell-des-o-2,
.cell-des-d-15 > .cell-des-o-3 {
  margin-left: 20%;
}
.cell-des-d-5 > .cell-des-n-2,
.cell-des-d-10 > .cell-des-n-4,
.cell-des-d-15 > .cell-des-n-6 {
  width: 40%;
}
.cell-des-d-5 > .cell-des-o-2,
.cell-des-d-10 > .cell-des-o-4,
.cell-des-d-15 > .cell-des-o-6 {
  margin-left: 40%;
}
.cell-des-d-5 > .cell-des-n-3,
.cell-des-d-10 > .cell-des-n-6,
.cell-des-d-15 > .cell-des-n-9 {
  width: 60%;
}
.cell-des-d-5 > .cell-des-o-3,
.cell-des-d-10 > .cell-des-o-6,
.cell-des-d-15 > .cell-des-o-9 {
  margin-left: 60%;
}
.cell-des-d-5 > .cell-des-n-4,
.cell-des-d-10 > .cell-des-n-8,
.cell-des-d-15 > .cell-des-n-12 {
  width: 80%;
}
.cell-des-d-5 > .cell-des-o-4,
.cell-des-d-10 > .cell-des-o-8,
.cell-des-d-15 > .cell-des-o-12 {
  margin-left: 80%;
}
.cell-des-d-6 > .cell-des-n-1,
.cell-des-d-12 > .cell-des-n-2 {
  width: 16.66666667%;
}
.cell-des-d-6 > .cell-des-o-1,
.cell-des-d-12 > .cell-des-o-2 {
  margin-left: 16.66666667%;
}
.cell-des-d-6 > .cell-des-n-5,
.cell-des-d-12 > .cell-des-n-10 {
  width: 83.33333333%;
}
.cell-des-d-6 > .cell-des-o-5,
.cell-des-d-12 > .cell-des-o-10 {
  margin-left: 83.33333333%;
}
.cell-des-d-7 > .cell-des-n-1,
.cell-des-d-14 > .cell-des-n-2 {
  width: 14.28571429%;
}
.cell-des-d-7 > .cell-des-o-1,
.cell-des-d-14 > .cell-des-o-2 {
  margin-left: 14.28571429%;
}
.cell-des-d-7 > .cell-des-n-2,
.cell-des-d-14 > .cell-des-n-4 {
  width: 28.57142857%;
}
.cell-des-d-7 > .cell-des-o-2,
.cell-des-d-14 > .cell-des-o-4 {
  margin-left: 28.57142857%;
}
.cell-des-d-7 > .cell-des-n-3,
.cell-des-d-14 > .cell-des-n-6 {
  width: 42.85714286%;
}
.cell-des-d-7 > .cell-des-o-3,
.cell-des-d-14 > .cell-des-o-6 {
  margin-left: 42.85714286%;
}
.cell-des-d-7 > .cell-des-n-4,
.cell-des-d-14 > .cell-des-n-8 {
  width: 57.14285714%;
}
.cell-des-d-7 > .cell-des-o-4,
.cell-des-d-14 > .cell-des-o-8 {
  margin-left: 57.14285714%;
}
.cell-des-d-7 > .cell-des-n-5,
.cell-des-d-14 > .cell-des-n-10 {
  width: 71.42857143%;
}
.cell-des-d-7 > .cell-des-o-5,
.cell-des-d-14 > .cell-des-o-10 {
  margin-left: 71.42857143%;
}
.cell-des-d-7 > .cell-des-n-6,
.cell-des-d-14 > .cell-des-n-12 {
  width: 85.71428571%;
}
.cell-des-d-7 > .cell-des-o-6,
.cell-des-d-14 > .cell-des-o-12 {
  margin-left: 85.71428571%;
}
.cell-des-d-8 > .cell-des-n-1,
.cell-des-d-16 > .cell-des-n-2 {
  width: 12.5%;
}
.cell-des-d-8 > .cell-des-o-1,
.cell-des-d-16 > .cell-des-o-2 {
  margin-left: 12.5%;
}
.cell-des-d-8 > .cell-des-n-3,
.cell-des-d-16 > .cell-des-n-6 {
  width: 37.5%;
}
.cell-des-d-8 > .cell-des-o-3,
.cell-des-d-16 > .cell-des-o-6 {
  margin-left: 37.5%;
}
.cell-des-d-8 > .cell-des-n-5,
.cell-des-d-16 > .cell-des-n-10 {
  width: 62.5%;
}
.cell-des-d-8 > .cell-des-o-5,
.cell-des-d-16 > .cell-des-o-10 {
  margin-left: 62.5%;
}
.cell-des-d-8 > .cell-des-n-7,
.cell-des-d-16 > .cell-des-n-14 {
  width: 87.5%;
}
.cell-des-d-8 > .cell-des-o-7,
.cell-des-d-16 > .cell-des-o-14 {
  margin-left: 87.5%;
}
.cell-des-d-9 > .cell-des-n-1 {
  width: 11.11111111%;
}
.cell-des-d-9 > .cell-des-o-1 {
  margin-left: 11.11111111%;
}
.cell-des-d-9 > .cell-des-n-2 {
  width: 22.22222222%;
}
.cell-des-d-9 > .cell-des-o-2 {
  margin-left: 22.22222222%;
}
.cell-des-d-9 > .cell-des-n-4 {
  width: 44.44444444%;
}
.cell-des-d-9 > .cell-des-o-4 {
  margin-left: 44.44444444%;
}
.cell-des-d-9 > .cell-des-n-5 {
  width: 55.55555556%;
}
.cell-des-d-9 > .cell-des-o-5 {
  margin-left: 55.55555556%;
}
.cell-des-d-9 > .cell-des-n-7 {
  width: 77.77777778%;
}
.cell-des-d-9 > .cell-des-o-7 {
  margin-left: 77.77777778%;
}
.cell-des-d-9 > .cell-des-n-8 {
  width: 88.88888889%;
}
.cell-des-d-9 > .cell-des-o-8 {
  margin-left: 88.88888889%;
}
.cell-des-d-10 > .cell-des-n-1 {
  width: 10%;
}
.cell-des-d-10 > .cell-des-o-1 {
  margin-left: 10%;
}
.cell-des-d-10 > .cell-des-n-3 {
  width: 30%;
}
.cell-des-d-10 > .cell-des-o-3 {
  margin-left: 30%;
}
.cell-des-d-10 > .cell-des-n-7 {
  width: 70%;
}
.cell-des-d-10 > .cell-des-o-7 {
  margin-left: 70%;
}
.cell-des-d-10 > .cell-des-n-9 {
  width: 90%;
}
.cell-des-d-10 > .cell-des-o-9 {
  margin-left: 90%;
}
.cell-des-d-11 > .cell-des-n-1 {
  width: 9.09090909%;
}
.cell-des-d-11 > .cell-des-o-1 {
  margin-left: 9.09090909%;
}
.cell-des-d-11 > .cell-des-n-2 {
  width: 18.18181818%;
}
.cell-des-d-11 > .cell-des-o-2 {
  margin-left: 18.18181818%;
}
.cell-des-d-11 > .cell-des-n-3 {
  width: 27.27272727%;
}
.cell-des-d-11 > .cell-des-o-3 {
  margin-left: 27.27272727%;
}
.cell-des-d-11 > .cell-des-n-4 {
  width: 36.36363636%;
}
.cell-des-d-11 > .cell-des-o-4 {
  margin-left: 36.36363636%;
}
.cell-des-d-11 > .cell-des-n-5 {
  width: 45.45454545%;
}
.cell-des-d-11 > .cell-des-o-5 {
  margin-left: 45.45454545%;
}
.cell-des-d-11 > .cell-des-n-6 {
  width: 54.54545455%;
}
.cell-des-d-11 > .cell-des-o-6 {
  margin-left: 54.54545455%;
}
.cell-des-d-11 > .cell-des-n-7 {
  width: 63.63636364%;
}
.cell-des-d-11 > .cell-des-o-7 {
  margin-left: 63.63636364%;
}
.cell-des-d-11 > .cell-des-n-8 {
  width: 72.72727273%;
}
.cell-des-d-11 > .cell-des-o-8 {
  margin-left: 72.72727273%;
}
.cell-des-d-11 > .cell-des-n-9 {
  width: 81.81818182%;
}
.cell-des-d-11 > .cell-des-o-9 {
  margin-left: 81.81818182%;
}
.cell-des-d-11 > .cell-des-n-10 {
  width: 90.90909091%;
}
.cell-des-d-11 > .cell-des-o-10 {
  margin-left: 90.90909091%;
}
.cell-des-d-12 > .cell-des-n-1 {
  width: 8.33333333%;
}
.cell-des-d-12 > .cell-des-o-1 {
  margin-left: 8.33333333%;
}
.cell-des-d-12 > .cell-des-n-5 {
  width: 41.66666667%;
}
.cell-des-d-12 > .cell-des-o-5 {
  margin-left: 41.66666667%;
}
.cell-des-d-12 > .cell-des-n-7 {
  width: 58.33333333%;
}
.cell-des-d-12 > .cell-des-o-7 {
  margin-left: 58.33333333%;
}
.cell-des-d-12 > .cell-des-n-11 {
  width: 91.66666667%;
}
.cell-des-d-12 > .cell-des-o-11 {
  margin-left: 91.66666667%;
}
.cell-des-d-13 > .cell-des-n-1 {
  width: 7.69230769%;
}
.cell-des-d-13 > .cell-des-o-1 {
  margin-left: 7.69230769%;
}
.cell-des-d-13 > .cell-des-n-2 {
  width: 15.38461538%;
}
.cell-des-d-13 > .cell-des-o-2 {
  margin-left: 15.38461538%;
}
.cell-des-d-13 > .cell-des-n-3 {
  width: 23.07692308%;
}
.cell-des-d-13 > .cell-des-o-3 {
  margin-left: 23.07692308%;
}
.cell-des-d-13 > .cell-des-n-4 {
  width: 30.76923077%;
}
.cell-des-d-13 > .cell-des-o-4 {
  margin-left: 30.76923077%;
}
.cell-des-d-13 > .cell-des-n-5 {
  width: 38.46153846%;
}
.cell-des-d-13 > .cell-des-o-5 {
  margin-left: 38.46153846%;
}
.cell-des-d-13 > .cell-des-n-6 {
  width: 46.15384615%;
}
.cell-des-d-13 > .cell-des-o-6 {
  margin-left: 46.15384615%;
}
.cell-des-d-13 > .cell-des-n-7 {
  width: 53.84615385%;
}
.cell-des-d-13 > .cell-des-o-7 {
  margin-left: 53.84615385%;
}
.cell-des-d-13 > .cell-des-n-8 {
  width: 61.53846154%;
}
.cell-des-d-13 > .cell-des-o-8 {
  margin-left: 61.53846154%;
}
.cell-des-d-13 > .cell-des-n-9 {
  width: 69.23076923%;
}
.cell-des-d-13 > .cell-des-o-9 {
  margin-left: 69.23076923%;
}
.cell-des-d-13 > .cell-des-n-10 {
  width: 76.92307692%;
}
.cell-des-d-13 > .cell-des-o-10 {
  margin-left: 76.92307692%;
}
.cell-des-d-13 > .cell-des-n-11 {
  width: 84.61538462%;
}
.cell-des-d-13 > .cell-des-o-11 {
  margin-left: 84.61538462%;
}
.cell-des-d-13 > .cell-des-n-12 {
  width: 92.30769231%;
}
.cell-des-d-13 > .cell-des-o-12 {
  margin-left: 92.30769231%;
}
.cell-des-d-14 > .cell-des-n-1 {
  width: 7.14285714%;
}
.cell-des-d-14 > .cell-des-o-1 {
  margin-left: 7.14285714%;
}
.cell-des-d-14 > .cell-des-n-3 {
  width: 21.42857143%;
}
.cell-des-d-14 > .cell-des-o-3 {
  margin-left: 21.42857143%;
}
.cell-des-d-14 > .cell-des-n-5 {
  width: 35.71428571%;
}
.cell-des-d-14 > .cell-des-o-5 {
  margin-left: 35.71428571%;
}
.cell-des-d-14 > .cell-des-n-9 {
  width: 64.28571429%;
}
.cell-des-d-14 > .cell-des-o-9 {
  margin-left: 64.28571429%;
}
.cell-des-d-14 > .cell-des-n-11 {
  width: 78.57142857%;
}
.cell-des-d-14 > .cell-des-o-11 {
  margin-left: 78.57142857%;
}
.cell-des-d-14 > .cell-des-n-13 {
  width: 92.85714286%;
}
.cell-des-d-14 > .cell-des-o-13 {
  margin-left: 92.85714286%;
}
.cell-des-d-15 > .cell-des-n-1 {
  width: 6.66666667%;
}
.cell-des-d-15 > .cell-des-o-1 {
  margin-left: 6.66666667%;
}
.cell-des-d-15 > .cell-des-n-2 {
  width: 13.33333333%;
}
.cell-des-d-15 > .cell-des-o-2 {
  margin-left: 13.33333333%;
}
.cell-des-d-15 > .cell-des-n-4 {
  width: 26.66666667%;
}
.cell-des-d-15 > .cell-des-o-4 {
  margin-left: 26.66666667%;
}
.cell-des-d-15 > .cell-des-n-7 {
  width: 46.66666667%;
}
.cell-des-d-15 > .cell-des-o-7 {
  margin-left: 46.66666667%;
}
.cell-des-d-15 > .cell-des-n-8 {
  width: 53.33333333%;
}
.cell-des-d-15 > .cell-des-o-8 {
  margin-left: 53.33333333%;
}
.cell-des-d-15 > .cell-des-n-11 {
  width: 73.33333333%;
}
.cell-des-d-15 > .cell-des-o-11 {
  margin-left: 73.33333333%;
}
.cell-des-d-15 > .cell-des-n-13 {
  width: 86.66666667%;
}
.cell-des-d-15 > .cell-des-o-13 {
  margin-left: 86.66666667%;
}
.cell-des-d-15 > .cell-des-n-14 {
  width: 93.33333333%;
}
.cell-des-d-15 > .cell-des-o-14 {
  margin-left: 93.33333333%;
}
.cell-des-d-16 > .cell-des-n-1 {
  width: 6.25%;
}
.cell-des-d-16 > .cell-des-o-1 {
  margin-left: 6.25%;
}
.cell-des-d-16 > .cell-des-n-3 {
  width: 18.75%;
}
.cell-des-d-16 > .cell-des-o-3 {
  margin-left: 18.75%;
}
.cell-des-d-16 > .cell-des-n-5 {
  width: 31.25%;
}
.cell-des-d-16 > .cell-des-o-5 {
  margin-left: 31.25%;
}
.cell-des-d-16 > .cell-des-n-7 {
  width: 43.75%;
}
.cell-des-d-16 > .cell-des-o-7 {
  margin-left: 43.75%;
}
.cell-des-d-16 > .cell-des-n-9 {
  width: 56.25%;
}
.cell-des-d-16 > .cell-des-o-9 {
  margin-left: 56.25%;
}
.cell-des-d-16 > .cell-des-n-11 {
  width: 68.75%;
}
.cell-des-d-16 > .cell-des-o-11 {
  margin-left: 68.75%;
}
.cell-des-d-16 > .cell-des-n-13 {
  width: 81.25%;
}
.cell-des-d-16 > .cell-des-o-13 {
  margin-left: 81.25%;
}
.cell-des-d-16 > .cell-des-n-15 {
  width: 93.75%;
}
.cell-des-d-16 > .cell-des-o-15 {
  margin-left: 93.75%;
}
@media only screen and (max-width : 1080px) {
  .cell-mob-d-1 > .cell-mob-n-1,
  .cell-mob-d-2 > .cell-mob-n-2,
  .cell-mob-d-3 > .cell-mob-n-3,
  .cell-mob-d-4 > .cell-mob-n-4,
  .cell-mob-d-5 > .cell-mob-n-5,
  .cell-mob-d-6 > .cell-mob-n-6,
  .cell-mob-d-7 > .cell-mob-n-7,
  .cell-mob-d-8 > .cell-mob-n-8,
  .cell-mob-d-9 > .cell-mob-n-9,
  .cell-mob-d-10 > .cell-mob-n-10,
  .cell-mob-d-11 > .cell-mob-n-11,
  .cell-mob-d-12 > .cell-mob-n-12,
  .cell-mob-d-13 > .cell-mob-n-13,
  .cell-mob-d-14 > .cell-mob-n-14,
  .cell-mob-d-15 > .cell-mob-n-15,
  .cell-mob-d-16 > .cell-mob-n-16 {
    width: 100%;
  }
  .cell-mob-d-1 > .cell-mob-o-1,
  .cell-mob-d-2 > .cell-mob-o-2,
  .cell-mob-d-3 > .cell-mob-o-3,
  .cell-mob-d-4 > .cell-mob-o-4,
  .cell-mob-d-5 > .cell-mob-o-5,
  .cell-mob-d-6 > .cell-mob-o-6,
  .cell-mob-d-7 > .cell-mob-o-7,
  .cell-mob-d-8 > .cell-mob-o-8,
  .cell-mob-d-9 > .cell-mob-o-9,
  .cell-mob-d-10 > .cell-mob-o-10,
  .cell-mob-d-11 > .cell-mob-o-11,
  .cell-mob-d-12 > .cell-mob-o-12,
  .cell-mob-d-13 > .cell-mob-o-13,
  .cell-mob-d-14 > .cell-mob-o-14,
  .cell-mob-d-15 > .cell-mob-o-15,
  .cell-mob-d-16 > .cell-mob-o-16 {
    margin-left: 100%;
  }
  .cell-mob-d-2 > .cell-mob-n-1,
  .cell-mob-d-4 > .cell-mob-n-2,
  .cell-mob-d-6 > .cell-mob-n-3,
  .cell-mob-d-8 > .cell-mob-n-4,
  .cell-mob-d-10 > .cell-mob-n-5,
  .cell-mob-d-12 > .cell-mob-n-6,
  .cell-mob-d-14 > .cell-mob-n-7,
  .cell-mob-d-16 > .cell-mob-n-8 {
    width: 50%;
  }
  .cell-mob-d-2 > .cell-mob-o-1,
  .cell-mob-d-4 > .cell-mob-o-2,
  .cell-mob-d-6 > .cell-mob-o-3,
  .cell-mob-d-8 > .cell-mob-o-4,
  .cell-mob-d-10 > .cell-mob-o-5,
  .cell-mob-d-12 > .cell-mob-o-6,
  .cell-mob-d-14 > .cell-mob-o-7,
  .cell-mob-d-16 > .cell-mob-o-8 {
    margin-left: 50%;
  }
  .cell-mob-d-3 > .cell-mob-n-1,
  .cell-mob-d-6 > .cell-mob-n-2,
  .cell-mob-d-9 > .cell-mob-n-3,
  .cell-mob-d-12 > .cell-mob-n-4,
  .cell-mob-d-15 > .cell-mob-n-5 {
    width: 33.33333333%;
  }
  .cell-mob-d-3 > .cell-mob-o-1,
  .cell-mob-d-6 > .cell-mob-o-2,
  .cell-mob-d-9 > .cell-mob-o-3,
  .cell-mob-d-12 > .cell-mob-o-4,
  .cell-mob-d-15 > .cell-mob-o-5 {
    margin-left: 33.33333333%;
  }
  .cell-mob-d-3 > .cell-mob-n-2,
  .cell-mob-d-6 > .cell-mob-n-4,
  .cell-mob-d-9 > .cell-mob-n-6,
  .cell-mob-d-12 > .cell-mob-n-8,
  .cell-mob-d-15 > .cell-mob-n-10 {
    width: 66.66666667%;
  }
  .cell-mob-d-3 > .cell-mob-o-2,
  .cell-mob-d-6 > .cell-mob-o-4,
  .cell-mob-d-9 > .cell-mob-o-6,
  .cell-mob-d-12 > .cell-mob-o-8,
  .cell-mob-d-15 > .cell-mob-o-10 {
    margin-left: 66.66666667%;
  }
  .cell-mob-d-4 > .cell-mob-n-1,
  .cell-mob-d-8 > .cell-mob-n-2,
  .cell-mob-d-12 > .cell-mob-n-3,
  .cell-mob-d-16 > .cell-mob-n-4 {
    width: 25%;
  }
  .cell-mob-d-4 > .cell-mob-o-1,
  .cell-mob-d-8 > .cell-mob-o-2,
  .cell-mob-d-12 > .cell-mob-o-3,
  .cell-mob-d-16 > .cell-mob-o-4 {
    margin-left: 25%;
  }
  .cell-mob-d-4 > .cell-mob-n-3,
  .cell-mob-d-8 > .cell-mob-n-6,
  .cell-mob-d-12 > .cell-mob-n-9,
  .cell-mob-d-16 > .cell-mob-n-12 {
    width: 75%;
  }
  .cell-mob-d-4 > .cell-mob-o-3,
  .cell-mob-d-8 > .cell-mob-o-6,
  .cell-mob-d-12 > .cell-mob-o-9,
  .cell-mob-d-16 > .cell-mob-o-12 {
    margin-left: 75%;
  }
  .cell-mob-d-5 > .cell-mob-n-1,
  .cell-mob-d-10 > .cell-mob-n-2,
  .cell-mob-d-15 > .cell-mob-n-3 {
    width: 20%;
  }
  .cell-mob-d-5 > .cell-mob-o-1,
  .cell-mob-d-10 > .cell-mob-o-2,
  .cell-mob-d-15 > .cell-mob-o-3 {
    margin-left: 20%;
  }
  .cell-mob-d-5 > .cell-mob-n-2,
  .cell-mob-d-10 > .cell-mob-n-4,
  .cell-mob-d-15 > .cell-mob-n-6 {
    width: 40%;
  }
  .cell-mob-d-5 > .cell-mob-o-2,
  .cell-mob-d-10 > .cell-mob-o-4,
  .cell-mob-d-15 > .cell-mob-o-6 {
    margin-left: 40%;
  }
  .cell-mob-d-5 > .cell-mob-n-3,
  .cell-mob-d-10 > .cell-mob-n-6,
  .cell-mob-d-15 > .cell-mob-n-9 {
    width: 60%;
  }
  .cell-mob-d-5 > .cell-mob-o-3,
  .cell-mob-d-10 > .cell-mob-o-6,
  .cell-mob-d-15 > .cell-mob-o-9 {
    margin-left: 60%;
  }
  .cell-mob-d-5 > .cell-mob-n-4,
  .cell-mob-d-10 > .cell-mob-n-8,
  .cell-mob-d-15 > .cell-mob-n-12 {
    width: 80%;
  }
  .cell-mob-d-5 > .cell-mob-o-4,
  .cell-mob-d-10 > .cell-mob-o-8,
  .cell-mob-d-15 > .cell-mob-o-12 {
    margin-left: 80%;
  }
  .cell-mob-d-6 > .cell-mob-n-1,
  .cell-mob-d-12 > .cell-mob-n-2 {
    width: 16.66666667%;
  }
  .cell-mob-d-6 > .cell-mob-o-1,
  .cell-mob-d-12 > .cell-mob-o-2 {
    margin-left: 16.66666667%;
  }
  .cell-mob-d-6 > .cell-mob-n-5,
  .cell-mob-d-12 > .cell-mob-n-10 {
    width: 83.33333333%;
  }
  .cell-mob-d-6 > .cell-mob-o-5,
  .cell-mob-d-12 > .cell-mob-o-10 {
    margin-left: 83.33333333%;
  }
  .cell-mob-d-7 > .cell-mob-n-1,
  .cell-mob-d-14 > .cell-mob-n-2 {
    width: 14.28571429%;
  }
  .cell-mob-d-7 > .cell-mob-o-1,
  .cell-mob-d-14 > .cell-mob-o-2 {
    margin-left: 14.28571429%;
  }
  .cell-mob-d-7 > .cell-mob-n-2,
  .cell-mob-d-14 > .cell-mob-n-4 {
    width: 28.57142857%;
  }
  .cell-mob-d-7 > .cell-mob-o-2,
  .cell-mob-d-14 > .cell-mob-o-4 {
    margin-left: 28.57142857%;
  }
  .cell-mob-d-7 > .cell-mob-n-3,
  .cell-mob-d-14 > .cell-mob-n-6 {
    width: 42.85714286%;
  }
  .cell-mob-d-7 > .cell-mob-o-3,
  .cell-mob-d-14 > .cell-mob-o-6 {
    margin-left: 42.85714286%;
  }
  .cell-mob-d-7 > .cell-mob-n-4,
  .cell-mob-d-14 > .cell-mob-n-8 {
    width: 57.14285714%;
  }
  .cell-mob-d-7 > .cell-mob-o-4,
  .cell-mob-d-14 > .cell-mob-o-8 {
    margin-left: 57.14285714%;
  }
  .cell-mob-d-7 > .cell-mob-n-5,
  .cell-mob-d-14 > .cell-mob-n-10 {
    width: 71.42857143%;
  }
  .cell-mob-d-7 > .cell-mob-o-5,
  .cell-mob-d-14 > .cell-mob-o-10 {
    margin-left: 71.42857143%;
  }
  .cell-mob-d-7 > .cell-mob-n-6,
  .cell-mob-d-14 > .cell-mob-n-12 {
    width: 85.71428571%;
  }
  .cell-mob-d-7 > .cell-mob-o-6,
  .cell-mob-d-14 > .cell-mob-o-12 {
    margin-left: 85.71428571%;
  }
  .cell-mob-d-8 > .cell-mob-n-1,
  .cell-mob-d-16 > .cell-mob-n-2 {
    width: 12.5%;
  }
  .cell-mob-d-8 > .cell-mob-o-1,
  .cell-mob-d-16 > .cell-mob-o-2 {
    margin-left: 12.5%;
  }
  .cell-mob-d-8 > .cell-mob-n-3,
  .cell-mob-d-16 > .cell-mob-n-6 {
    width: 37.5%;
  }
  .cell-mob-d-8 > .cell-mob-o-3,
  .cell-mob-d-16 > .cell-mob-o-6 {
    margin-left: 37.5%;
  }
  .cell-mob-d-8 > .cell-mob-n-5,
  .cell-mob-d-16 > .cell-mob-n-10 {
    width: 62.5%;
  }
  .cell-mob-d-8 > .cell-mob-o-5,
  .cell-mob-d-16 > .cell-mob-o-10 {
    margin-left: 62.5%;
  }
  .cell-mob-d-8 > .cell-mob-n-7,
  .cell-mob-d-16 > .cell-mob-n-14 {
    width: 87.5%;
  }
  .cell-mob-d-8 > .cell-mob-o-7,
  .cell-mob-d-16 > .cell-mob-o-14 {
    margin-left: 87.5%;
  }
  .cell-mob-d-9 > .cell-mob-n-1 {
    width: 11.11111111%;
  }
  .cell-mob-d-9 > .cell-mob-o-1 {
    margin-left: 11.11111111%;
  }
  .cell-mob-d-9 > .cell-mob-n-2 {
    width: 22.22222222%;
  }
  .cell-mob-d-9 > .cell-mob-o-2 {
    margin-left: 22.22222222%;
  }
  .cell-mob-d-9 > .cell-mob-n-4 {
    width: 44.44444444%;
  }
  .cell-mob-d-9 > .cell-mob-o-4 {
    margin-left: 44.44444444%;
  }
  .cell-mob-d-9 > .cell-mob-n-5 {
    width: 55.55555556%;
  }
  .cell-mob-d-9 > .cell-mob-o-5 {
    margin-left: 55.55555556%;
  }
  .cell-mob-d-9 > .cell-mob-n-7 {
    width: 77.77777778%;
  }
  .cell-mob-d-9 > .cell-mob-o-7 {
    margin-left: 77.77777778%;
  }
  .cell-mob-d-9 > .cell-mob-n-8 {
    width: 88.88888889%;
  }
  .cell-mob-d-9 > .cell-mob-o-8 {
    margin-left: 88.88888889%;
  }
  .cell-mob-d-10 > .cell-mob-n-1 {
    width: 10%;
  }
  .cell-mob-d-10 > .cell-mob-o-1 {
    margin-left: 10%;
  }
  .cell-mob-d-10 > .cell-mob-n-3 {
    width: 30%;
  }
  .cell-mob-d-10 > .cell-mob-o-3 {
    margin-left: 30%;
  }
  .cell-mob-d-10 > .cell-mob-n-7 {
    width: 70%;
  }
  .cell-mob-d-10 > .cell-mob-o-7 {
    margin-left: 70%;
  }
  .cell-mob-d-10 > .cell-mob-n-9 {
    width: 90%;
  }
  .cell-mob-d-10 > .cell-mob-o-9 {
    margin-left: 90%;
  }
  .cell-mob-d-11 > .cell-mob-n-1 {
    width: 9.09090909%;
  }
  .cell-mob-d-11 > .cell-mob-o-1 {
    margin-left: 9.09090909%;
  }
  .cell-mob-d-11 > .cell-mob-n-2 {
    width: 18.18181818%;
  }
  .cell-mob-d-11 > .cell-mob-o-2 {
    margin-left: 18.18181818%;
  }
  .cell-mob-d-11 > .cell-mob-n-3 {
    width: 27.27272727%;
  }
  .cell-mob-d-11 > .cell-mob-o-3 {
    margin-left: 27.27272727%;
  }
  .cell-mob-d-11 > .cell-mob-n-4 {
    width: 36.36363636%;
  }
  .cell-mob-d-11 > .cell-mob-o-4 {
    margin-left: 36.36363636%;
  }
  .cell-mob-d-11 > .cell-mob-n-5 {
    width: 45.45454545%;
  }
  .cell-mob-d-11 > .cell-mob-o-5 {
    margin-left: 45.45454545%;
  }
  .cell-mob-d-11 > .cell-mob-n-6 {
    width: 54.54545455%;
  }
  .cell-mob-d-11 > .cell-mob-o-6 {
    margin-left: 54.54545455%;
  }
  .cell-mob-d-11 > .cell-mob-n-7 {
    width: 63.63636364%;
  }
  .cell-mob-d-11 > .cell-mob-o-7 {
    margin-left: 63.63636364%;
  }
  .cell-mob-d-11 > .cell-mob-n-8 {
    width: 72.72727273%;
  }
  .cell-mob-d-11 > .cell-mob-o-8 {
    margin-left: 72.72727273%;
  }
  .cell-mob-d-11 > .cell-mob-n-9 {
    width: 81.81818182%;
  }
  .cell-mob-d-11 > .cell-mob-o-9 {
    margin-left: 81.81818182%;
  }
  .cell-mob-d-11 > .cell-mob-n-10 {
    width: 90.90909091%;
  }
  .cell-mob-d-11 > .cell-mob-o-10 {
    margin-left: 90.90909091%;
  }
  .cell-mob-d-12 > .cell-mob-n-1 {
    width: 8.33333333%;
  }
  .cell-mob-d-12 > .cell-mob-o-1 {
    margin-left: 8.33333333%;
  }
  .cell-mob-d-12 > .cell-mob-n-5 {
    width: 41.66666667%;
  }
  .cell-mob-d-12 > .cell-mob-o-5 {
    margin-left: 41.66666667%;
  }
  .cell-mob-d-12 > .cell-mob-n-7 {
    width: 58.33333333%;
  }
  .cell-mob-d-12 > .cell-mob-o-7 {
    margin-left: 58.33333333%;
  }
  .cell-mob-d-12 > .cell-mob-n-11 {
    width: 91.66666667%;
  }
  .cell-mob-d-12 > .cell-mob-o-11 {
    margin-left: 91.66666667%;
  }
  .cell-mob-d-13 > .cell-mob-n-1 {
    width: 7.69230769%;
  }
  .cell-mob-d-13 > .cell-mob-o-1 {
    margin-left: 7.69230769%;
  }
  .cell-mob-d-13 > .cell-mob-n-2 {
    width: 15.38461538%;
  }
  .cell-mob-d-13 > .cell-mob-o-2 {
    margin-left: 15.38461538%;
  }
  .cell-mob-d-13 > .cell-mob-n-3 {
    width: 23.07692308%;
  }
  .cell-mob-d-13 > .cell-mob-o-3 {
    margin-left: 23.07692308%;
  }
  .cell-mob-d-13 > .cell-mob-n-4 {
    width: 30.76923077%;
  }
  .cell-mob-d-13 > .cell-mob-o-4 {
    margin-left: 30.76923077%;
  }
  .cell-mob-d-13 > .cell-mob-n-5 {
    width: 38.46153846%;
  }
  .cell-mob-d-13 > .cell-mob-o-5 {
    margin-left: 38.46153846%;
  }
  .cell-mob-d-13 > .cell-mob-n-6 {
    width: 46.15384615%;
  }
  .cell-mob-d-13 > .cell-mob-o-6 {
    margin-left: 46.15384615%;
  }
  .cell-mob-d-13 > .cell-mob-n-7 {
    width: 53.84615385%;
  }
  .cell-mob-d-13 > .cell-mob-o-7 {
    margin-left: 53.84615385%;
  }
  .cell-mob-d-13 > .cell-mob-n-8 {
    width: 61.53846154%;
  }
  .cell-mob-d-13 > .cell-mob-o-8 {
    margin-left: 61.53846154%;
  }
  .cell-mob-d-13 > .cell-mob-n-9 {
    width: 69.23076923%;
  }
  .cell-mob-d-13 > .cell-mob-o-9 {
    margin-left: 69.23076923%;
  }
  .cell-mob-d-13 > .cell-mob-n-10 {
    width: 76.92307692%;
  }
  .cell-mob-d-13 > .cell-mob-o-10 {
    margin-left: 76.92307692%;
  }
  .cell-mob-d-13 > .cell-mob-n-11 {
    width: 84.61538462%;
  }
  .cell-mob-d-13 > .cell-mob-o-11 {
    margin-left: 84.61538462%;
  }
  .cell-mob-d-13 > .cell-mob-n-12 {
    width: 92.30769231%;
  }
  .cell-mob-d-13 > .cell-mob-o-12 {
    margin-left: 92.30769231%;
  }
  .cell-mob-d-14 > .cell-mob-n-1 {
    width: 7.14285714%;
  }
  .cell-mob-d-14 > .cell-mob-o-1 {
    margin-left: 7.14285714%;
  }
  .cell-mob-d-14 > .cell-mob-n-3 {
    width: 21.42857143%;
  }
  .cell-mob-d-14 > .cell-mob-o-3 {
    margin-left: 21.42857143%;
  }
  .cell-mob-d-14 > .cell-mob-n-5 {
    width: 35.71428571%;
  }
  .cell-mob-d-14 > .cell-mob-o-5 {
    margin-left: 35.71428571%;
  }
  .cell-mob-d-14 > .cell-mob-n-9 {
    width: 64.28571429%;
  }
  .cell-mob-d-14 > .cell-mob-o-9 {
    margin-left: 64.28571429%;
  }
  .cell-mob-d-14 > .cell-mob-n-11 {
    width: 78.57142857%;
  }
  .cell-mob-d-14 > .cell-mob-o-11 {
    margin-left: 78.57142857%;
  }
  .cell-mob-d-14 > .cell-mob-n-13 {
    width: 92.85714286%;
  }
  .cell-mob-d-14 > .cell-mob-o-13 {
    margin-left: 92.85714286%;
  }
  .cell-mob-d-15 > .cell-mob-n-1 {
    width: 6.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-o-1 {
    margin-left: 6.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-n-2 {
    width: 13.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-o-2 {
    margin-left: 13.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-n-4 {
    width: 26.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-o-4 {
    margin-left: 26.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-n-7 {
    width: 46.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-o-7 {
    margin-left: 46.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-n-8 {
    width: 53.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-o-8 {
    margin-left: 53.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-n-11 {
    width: 73.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-o-11 {
    margin-left: 73.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-n-13 {
    width: 86.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-o-13 {
    margin-left: 86.66666667%;
  }
  .cell-mob-d-15 > .cell-mob-n-14 {
    width: 93.33333333%;
  }
  .cell-mob-d-15 > .cell-mob-o-14 {
    margin-left: 93.33333333%;
  }
  .cell-mob-d-16 > .cell-mob-n-1 {
    width: 6.25%;
  }
  .cell-mob-d-16 > .cell-mob-o-1 {
    margin-left: 6.25%;
  }
  .cell-mob-d-16 > .cell-mob-n-3 {
    width: 18.75%;
  }
  .cell-mob-d-16 > .cell-mob-o-3 {
    margin-left: 18.75%;
  }
  .cell-mob-d-16 > .cell-mob-n-5 {
    width: 31.25%;
  }
  .cell-mob-d-16 > .cell-mob-o-5 {
    margin-left: 31.25%;
  }
  .cell-mob-d-16 > .cell-mob-n-7 {
    width: 43.75%;
  }
  .cell-mob-d-16 > .cell-mob-o-7 {
    margin-left: 43.75%;
  }
  .cell-mob-d-16 > .cell-mob-n-9 {
    width: 56.25%;
  }
  .cell-mob-d-16 > .cell-mob-o-9 {
    margin-left: 56.25%;
  }
  .cell-mob-d-16 > .cell-mob-n-11 {
    width: 68.75%;
  }
  .cell-mob-d-16 > .cell-mob-o-11 {
    margin-left: 68.75%;
  }
  .cell-mob-d-16 > .cell-mob-n-13 {
    width: 81.25%;
  }
  .cell-mob-d-16 > .cell-mob-o-13 {
    margin-left: 81.25%;
  }
  .cell-mob-d-16 > .cell-mob-n-15 {
    width: 93.75%;
  }
  .cell-mob-d-16 > .cell-mob-o-15 {
    margin-left: 93.75%;
  }
}
